import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  inputRetailerName: '',
  valueRetailerName: {},
  holdValueRetailer: {}, // hold and to track if retailer change
  retailerID: '',
  listProducts: [],
  isClickNavbar: false,
  isEditConsignmentStockReturnableMode: false, // for edit
  stockReturnableID: '', // for edit,
  chosenRMAAddressID: '', // TEMPORARY HOLD FOR RADIOGROUP
  chosenRMAAddressDetail: {}, // TEMPORARY HOLD FOR RADIOGROUP
  finalChosenRMAAddressID: '', 
  finalChosenRMAAddressDetail: {}
}

const slice = createSlice({
  name: 'consignmentStockReturnable',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setInputRetailerName(state, action) {
      state.isLoading = false;
      state.inputRetailerName = action.payload;
    },

    setValueRetailerName(state, action) {
      state.isLoading = false;
      state.valueRetailerName = action.payload;
    },

    setHoldValueRetailer(state, action) {
      state.isLoading = false;
      state.holdValueRetailer = action.payload;
    },
    
    setRetailerID(state, action) {
      state.isLoading = false;
      state.retailerID = action.payload;
    },

    setListProducts(state, action) {
      state.isLoading = false;
      state.listProducts = action.payload;
    },

    setIsClickNavbar(state, action) {
      state.isLoading = false;
      state.isClickNavbar = action.payload;
    },

    setIsEditConsignmentStockReturnableMode(state, action) {
      state.isLoading = false;
      state.isEditConsignmentStockReturnableMode = action.payload;
    },

    setStockReturnableID(state, action) {
      state.isLoading = false;
      state.stockReturnableID = action.payload;
    },

    setChosenRMAAddressID(state, action) {
      state.isLoading = false;
      state.chosenRMAAddressID = action.payload;
    },

    setChosenRMAAddressDetail(state, action) {
      state.isLoading = false;
      state.chosenRMAAddressDetail = action.payload;
    },

    setFinalChosenRMAAddressID(state, action) {
      state.isLoading = false;
      state.finalChosenRMAAddressID = action.payload;
    },

    setFinalChosenRMAAddressDetail(state, action) {
      state.isLoading = false;
      state.finalChosenRMAAddressDetail = action.payload;
    },

    resetStockReturnable(state) {
      state.listProducts = []
      state.inputRetailerName = ''
      state.valueRetailerName = {}
      state.retailerID = ''
      state.holdValueRetailer = {}
      state.isClickNavbar = false
      state.isEditConsignmentStockReturnableMode = false
      state.stockReturnableID = ''
      state.chosenRMAAddressID = ''
      state.chosenRMAAddressDetail = ''
      state.finalChosenRMAAddressID = ''
      state.finalChosenRMAAddressDetail = ''
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
} = slice.actions;

// ----------------------------------------------------------------------
export function setInputRetailerName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setInputRetailerName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setValueRetailerName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setValueRetailerName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setHoldValueRetailer(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setHoldValueRetailer(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setRetailerID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setRetailerID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setListProducts(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setListProducts(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setIsClickNavbar(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setIsClickNavbar(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setIsEditConsignmentStockReturnableMode(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setIsEditConsignmentStockReturnableMode(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setStockReturnableID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setStockReturnableID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setChosenRMAAddressID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setChosenRMAAddressID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setChosenRMAAddressDetail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setChosenRMAAddressDetail(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setFinalChosenRMAAddressID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setFinalChosenRMAAddressID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setFinalChosenRMAAddressDetail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setFinalChosenRMAAddressDetail(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function resetStockReturnable() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.resetStockReturnable())
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}